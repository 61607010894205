<template>
  <v-container class="fill-height">
    <AppToolbar></AppToolbar>
    <v-row v-if="isLoading" class="fill-height" align="center" justify="center">
      <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>
    </v-row>

    <template v-if="!sites">
      No sites
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AppToolbar from '@/components/AppToolbar'

export default {
  name: 'Home',

  components: {
    AppToolbar
  },

  computed: {
    ...mapGetters('site', {
      sites: 'list'
    }),
    ...mapFields('site', {
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },

  watch: {
    sites(sites) {
      if (!sites.length) {
        return
      }

      this.$router.push({ name: 'siteShow', params: { id: this.$iriToId(sites[0]['@id']) } })
    }
  },

  mounted() {
    this.getPage({ pagination: 'false' })
  },

  methods: {
    ...mapActions('site', {
      getPage: 'fetchAll'
    })
  }
}
</script>
